<template>
  <section class="features_teasers_wrapper">
    <div class="container">
      <div class="row">
        <div class="feature_teaser col-sm-4 col-md-4" v-for="(features, index) in $store.state.text.features"
             :key="'features'+index">
          <img :alt="features.title" :src="images[index]" class="feature-img">
          <h3>{{ features.title }}</h3>
          <p>{{ features.text }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import image1 from '@/assets/img/device.svg';
import image2 from '@/assets/img/060-webpage-3.svg';
import image3 from '@/assets/img/043-webpage-6.svg';

export default {
  name: "Features",
  data() {
    return {
      images: [
        image1, image2, image3
      ]
    }
  }
}
</script>

<style scoped>
.feature-img{
  max-width: 140px;
}
</style>