<template>
  <div class="overflow_wrapper">
    <Header/>
    <Slider/>
    <div class="main">
      <div class="container triangles-of-section">
        <div class="triangle-up-left"></div>
        <div class="square-left"></div>
        <div class="triangle-up-right"></div>
        <div class="square-right"></div>
      </div>
      <Features/>
      <Slogan/>
      <Portfolio/>
      <Contact/>
    </div>
  </div>
</template>

<script>

import Header from "./Header";
import Slider from "./Slider";
import Features from "./Features";
import Slogan from "@/components/Slogan";
import Portfolio from "@/components/Portfolio";
import Contact from "@/components/Contact";

export default {
  name: "MainSite",
  components: {Contact, Portfolio, Slogan, Features, Slider, Header},
}
</script>

<style scoped>

</style>