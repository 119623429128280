<template>
  <section class="portfolio_teasers_wrapper">
    <div class="container triangles-of-section">
      <div class="triangle-up-left"></div>
      <div class="square-left"></div>
      <div class="triangle-up-right"></div>
      <div class="square-right"></div>
    </div>
    <div class="container" v-if="1==2">
      <h2 class="section_header fancy centered">Recent Projects from Portfolio<small>we take pride in our
        work</small></h2>
      <div class="portfolio_strict row">
        <div class="col-sm-4 col-md-4">
          <div class="portfolio_item wow fadeInUp"><a href="portfolio_item.html">
            <figure style="background-image:url(images/portfolio/b3-home.jpg)">
              <figcaption>
                <div class="portfolio_description">
                  <h3>The CleanStart Project</h3>
                  <span class="cross"></span>
                  <p>Design</p>
                </div>
              </figcaption>
            </figure>
          </a></div>
        </div>
        <div class="col-sm-4 col-md-4">
          <div class="portfolio_item wow fadeInUp"><a href="portfolio_item.html">
            <figure style="background-image:url(images/portfolio/a4-home.jpg)">
              <figcaption>
                <div class="portfolio_description">
                  <h3>The CleanStart Project</h3>
                  <span class="cross"></span>
                  <p>Design</p>
                </div>
              </figcaption>
            </figure>
          </a></div>
        </div>
        <div class="col-sm-4 col-md-4">
          <div class="portfolio_item wow fadeInUp"><a href="portfolio_item.html">
            <figure style="background-image:url(images/portfolio/t5-home.jpg)">
              <figcaption>
                <div class="portfolio_description">
                  <h3>The CleanStart Project</h3>
                  <span class="cross"></span>
                  <p>Design</p>
                </div>
              </figcaption>
            </figure>
          </a></div>
        </div>
      </div>
      <div class="centered_button"><a class="btn btn-primary" href="portfolio.html">more work</a></div>
    </div>
    <div class="clients_section wow fadeInUp">
      <div class="container">
        <h2 class="section_header elegant centered">{{ $store.state.text.customers.title }}
          <small>{{ $store.state.text.customers.text }}</small></h2>
        <div class="clients_list">
          <a href="client.link" v-for="(client,index) in clients" :key="'client'+index">
            <img :src="client.image" :alt="client.title" class="client-logo">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import norbitlogo from '@/assets/img/client/norbit-logo.svg';
import vexcorplogo from '@/assets/img/client/vexCorpLogo.png';
import shlogologo from '@/assets/img/client/sh_logo.png';
import zwlogo from '@/assets/img/client/zw_logo.png';
import oslogo from '@/assets/img/client/oslogo.png';

export default {
  name: "Portfolio",
  data() {
    return {
      clients: [
        {
          title: 'Omni-Soft',
          image: oslogo,
          link: 'https://omni-soft.dev'
        },
        {
          title: 'NORBiT',
          image: norbitlogo,
          link: 'https://www.norbit.pl'
        },
        {
          title: 'VEX Corp',
          image: vexcorplogo,
          link: ''
        },
        {
          title: 'Sardaukar Harvest',
          image: shlogologo,
          link: ''
        },
        {
          title: 'Zawodowy Windykator',
          image: zwlogo,
          link: ''
        }
      ]
    }
  }
}
</script>

<style scoped>
.client-logo {
  max-height: 50px;
}
</style>