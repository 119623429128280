<template>
  <header>
    <div class="container">
      <div class="logo"><a class="brand" href="/">
        <span class="logo_title">
          {{$store.state.text.app.title}}
        </span></a></div>

      <!-- MAIN MENU -->
      <MenuTop/>
      <!-- /MAIN MENU -->

      <div class="triangle-up-left"></div>
      <div class="triangle-up-right"></div>
    </div>
  </header>
</template>

<script>
import MenuTop from "./MenuTop";
import logo from '@/assets/img/logo.svg';

export default {
  name: "Header",
  components: {MenuTop},
  data() {
    return {
      logo,
    }
  }
}
</script>

<style scoped>
.logo a.brand {
  overflow: inherit;
}

.logo a.brand img {
  height: 40px;
  margin: -5px 8px 2px 0;
}
</style>