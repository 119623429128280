<template>
  <section class="call_to_action dark_section">
    <div class="container">
      <h3 v-html="$store.state.text.slogan.title"/>
      <h4 v-html="$store.state.text.slogan.text"/>
      </div>
  </section>
</template>

<script>
export default {
name: "Slogan"
}
</script>

<style scoped>

</style>