<template>
  <div>
    <section class="twitter_feed_wrapper skincolored_section visible-xs">
      <div class="container">
        <div class="row">
        </div>
      </div>
    </section>
    <section id="contact" class="onepage">
      <footer>
        <section class="contact">
          <div id="map">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d80127.55468535425!2d16.921824505978645!3d51.12716471938331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe9c2d4b58abf%3A0xb70956aec205e0f5!2zV3JvY8WCYXc!5e0!3m2!1spl!2spl!4v1608289553390!5m2!1spl!2spl"
                width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
                tabindex="0"></iframe>
          </div>
          <div class="btn btn-primary-inv open_map"><i class="fa fa-flag"></i> {{ text.contact.buttons.showMap }}</div>
          <div class="btn btn-danger close_map"><i class="fa fa-flag"></i> {{ text.contact.buttons.showForm }}</div>
          <div class="contact_form_overlay">
            <div class="container">
              <div class="row">
                <div class="office_address col-sm-4 col-md-4">
                  <div class="team_member">
                    <h5>{{ text.contact.company.name }}</h5>
                    <br>
                    <address>
                      {{ text.contact.company.address }}
                    </address>
                  </div>
                </div>
                <div class="contact_form col-sm-8 col-md-8">
                  <form name="contact_form" id="contact_form" method="post">
                    <div class="row">
                      <div class="col-sm-6 col-md-6">
                        <label>{{ text.contact.form.name }}</label>
                        <input name="name" id="name" class="form-control" type="text" v-model="form.name">
                      </div>
                      <div class="col-sm-6 col-md-6">
                        <label>{{ text.contact.form.email }}</label>
                        <input name="email" id="email" class="form-control" type="text" v-model="form.email">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-12">
                        <label>{{ text.contact.form.subject }}</label>
                        <input name="subject" id="subject" class="form-control" type="text" v-model="form.subject">
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-md-12">
                        <label>{{ text.contact.form.message }}</label>
                        <textarea name="message" id="message" rows="8" class="form-control"
                                  v-model="form.message"></textarea>
                      </div>
                      <div class="col-sm-12 col-md-12"><br/>
                        <a id="submit_btn" class="btn btn-primary-inv" name="submit"
                           @click="sendForm">{{ text.contact.buttons.submit }}</a>
                        <span id="notice"
                              class="alert alert-warning alert-dismissable hidden"
                              style="margin-left:20px;"></span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="copyright">
          <div class="container triangles-of-section">
            <div class="triangle-up-left"></div>
            <div class="square-left"></div>
            <div class="triangle-up-right"></div>
            <div class="square-right"></div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-md-6"></div>
              <div class="text-right col-sm-6 col-md-6">
                Icons made by <a href="https://www.flaticon.com/authors/dinosoftlabs"
                                 title="DinosoftLabs">DinosoftLabs</a> from
                <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </section>
  </div>
</template>

<script>
import logo from '@/assets/img/logo.svg';

export default {
  name: "Contact",
  data() {
    return {
      logo,
      form: {
        name: null,
        email: null,
        subject: null,
        message: null,
      }
    }
  },
  computed: {
    text() {
      return this.$store.state.text;
    }
  },
  methods: {
    sendForm() {
      const form = new FormData();
      form.append('userName', this.form.name);
      form.append('userEmail', this.form.email);
      form.append('userSubject', this.form.subject);
      form.append('userMessage', this.form.message);
      this.axios.post('contact.php', form)
          .then(() => alert(this.text.contact.messageSent))
          .catch(() => alert(this.text.contact.messageError));
    }
  }
}
</script>

<style scoped>
.contact-logo {
  max-width: 150px;
}

.contact_form_overlay {
  padding-top: 7px;
}

.onepage .close_map, .onepage .open_map {
  left: 0;
}

@media only screen and (max-width: 768px) {
  .onepage .close_map, .onepage .open_map {
    top: unset;
  }
}

</style>