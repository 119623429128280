const lang = {
    en: {
        app: {
            title: 'AK DEV'
        },
        menu: {
            home: 'Home',
            contact: 'Contact'
        },
        slider: [
            {
                title: "Website <strong>suited</strong> for you",
                text: "Every project is tailored to client"
            },
            {
                title: "Software <strong>needed</strong> by your company",
                text: "You get exactly what you need"
            }
        ],
        features: [
            {
                title: "Desktop/Mobile",
                text: "I create applications available either on desktop and mobile devices"
            },
            {
                title: "CMS",
                text: "For content managment I'm using my own CMS, which is always tailored to client needs"
            },
            {
                title: "Functionality",
                text: "Each client is individual and created software fits exactly to what he needs"
            }
        ],
        slogan: {
            title: "focus on what’s <strong><span class=\"rotate\">important</span></strong>",
            text: "I will take care for the software"
        },
        customers: {
            title: "My dear clients",
            text: "which use my service"
        },
        contact: {
            title: "Contact details",
            company: {
                name: "AK DEV",
                address: "Wrocław, Poland"
            },
            buttons: {
                showMap: "show map",
                showForm: "show contact form",
                submit: "submit message"
            },
            form: {
                name: "Name",
                email: "E-mail",
                subject: "Subject",
                message: "Message"
            },
            messageSent: "Message was sent. Thank you.",
            messageError: "Something went wrong. Please check form and try again."
        }
    }
}

export default lang;