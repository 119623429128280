<template>
  <section id="slider_wrapper" class="slider_wrapper full_page_photo">
    <div id="main_flexslider" class="flexslider">
      <ul class="slides">
        <li class="item" v-for="(slider, index) in $store.state.text.slider" :key="'slider_'+index"
            :style="'background-image: url('+sliderImg[index]+')'">
          <div class="container">
            <div class="carousel-caption animated bounceInUp">
              <h1 v-html="slider.title"/>
              <p class="lead skincolored" v-html="slider.text"/>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>
<script>
import slider1 from '@/assets/img/1.jpg';
import slider2 from '@/assets/img/2.jpg';

export default {
  name: "Slider",
  data() {
    return {
      sliderImg: [
        slider1,
        slider2
      ]
    }
  },
}
</script>

<style scoped>

</style>